import { RouteConfig } from "vue-router";

const CrmRemindersRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Reminders",
    component: () => import("@/views/Reminder/Calendar/Calendar.vue"),
    meta: {
      requiresLogged: true,
    },
    props: true,
  },
];

export default CrmRemindersRoutes;
