<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template v-if="dialogSMS">
      <v-snackbar v-model="dialogSMS" color="#ffffff" :right="right" :left="left" auto-height width="600px"
        content-class="snackcall  " :top="mini" elevation="1" :timeout="-1">
        <div class="d-flex">
          <v-btn v-if="!left" color="warning" icon @click="toLeft"><v-icon>mdi-arrow-left-bold</v-icon></v-btn>

          <v-spacer></v-spacer>

          <v-btn icon @click="stopSms" color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-if="!right" color="warning" icon @click="toRight"><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
        </div>
        <v-sheet class="sheet " color="white" elevation="0" height="700" width="100%">
          <v-toolbar class="toolbarsheet"  elevation="99" dark color="secondary">
            <v-toolbar-title class="d-flex flex-column align-center">
              <h3 class="texttoolbar">{{ name }}</h3>
              <span class="texttoolbar">{{ number }}</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <template>
            <section class="section-sms" :loading="loading">
              <main id="#container">
                <template v-if="moreSms && !loading">
                  <div class="d-flex justify-center  container-more ">
                    <v-btn text color="info" @click="getMore"><v-icon>mdi-message-processing</v-icon> more sms</v-btn>
                  </div>
                </template>
                <div class="wrapper-sms">
                  <div :id="index == _smsShow.length - 1 ? 'last' : index" v-for="(msg, index) in _smsShow"
                  v-bind:key="'index-' + index" :class="['message ']">
                  <v-sheet v-if="isDiferentMessage(index, _smsShow)" :key="'s-' + index" color="var(--crm-background)"
                    style="min-height: 24px; top: 0; z-index: 1" v-ripple rounded small>
                    <!--  <span
              class="text-caption font-weight-bold grey--text darken-4"
              v-if="isSend(message)"
            >
              {{ formatDate(message.createAt) }}
            </span> -->
                    <span class="text-caption font-weight-bold grey--text darken-4">
                      {{ formatDate(msg.createAt) }}
                    </span>
                  </v-sheet>
                  <template v-if="sendOrReceived(msg.from) == 'send'">
                    <message-send :medias="msg.mediasUrl" v-bind="msg" />
                  </template>
                  <template v-else>
                    <message-received :medias="msg.mediasUrl" v-bind="msg" />
                  </template>
                </div>
                <div id="scrollable"></div>
                </div>
            
              </main>
              <message-input  :global="true" :loading="loading" @send-message="sendMessage" />
            </section>
          </template>
        </v-sheet>
      </v-snackbar>
    </template>
  </div>
</template>
<script>
import MessageInput from "@/views/SMS/Messages/MessageInput.vue";
import MessageReceived from "@/views/SMS/Messages/MessageReceived.vue";
import MessageSend from "@/views/SMS/Messages/MessageSend.vue";
import {  mapActions, mapMutations, mapState } from "vuex";
import formatPhone from "@/utils/formatPhone";
import moment from "moment";
import { getAPI } from '@/api/axios-base';
import { notifyError } from '@/components/Notification';
export default {
  name: "sms-global",
  components: { MessageInput, MessageReceived, MessageSend },
  data() {
    return {
      number: "",
      name: "",
      loading: false,
      dialogSMS: false,
      smsS: [],
      right: true,
      left: false,
      mini: false,
      activeSms: null,

      page:0,
      limit:20,
      moreSms:false,
    };
  },
  computed: {
    ...mapState(["showSmsGlobal",'activesms']),
    ...mapState("crmSMSModule", [
      "messagesPatient",
      "messages",
      "contactSelect",
    ]),

    _smsShow() {
      if (this.smsS.length == 0) {
        return [];
      }
      const contacto = this.contactSelect;
      if (contacto != null) {
        const msg = this.smsS.filter(
          (sms) => sms.from == contacto.number || sms.to == contacto.number
        );

        return msg;
      } else {
        return [];
      }
    },
  },
  watch: {
    showSmsGlobal(val) {
      this.dialogSMS = val;
      if (val) {
        this.loading = true;
        this.page=0
        this.initSms();
        
      }
    },
    activesms(val) {
    if (val) {
    this.page=0;
    this.getMessages(this.page);
    }
    },
  },
  methods: {
    ...mapMutations(["mutShowSms"]),
    ...mapActions("crmSMSModule", ["actReadMessages",
    ]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutSetMessagesPatient",
      "mutSetMessages",
    ]),
    

    sendMessage() {
      this.page=0
      this.getMessages(this.page);
    },
    toRight() {
      this.right = true;
      this.left = false;
    },
    toLeft() {
      this.right = false;
      this.left = true;
    },
    initSms: function () {
      this.mutSetMessagesPatient([]);
      this.name = localStorage.getItem("nameSMS")
        ? localStorage.getItem("nameSMS")
        : "-";
      let phonetemp = localStorage.getItem("numberSMS")
        ? localStorage.getItem("numberSMS")
        : "-";

      if (phonetemp.includes("+1")) {
        phonetemp = phonetemp.replace("+1", "");
      }
      phonetemp = phonetemp.replaceAll("\t", "");
      phonetemp = phonetemp.replaceAll(" ", "");
      phonetemp = formatPhone(phonetemp);
      phonetemp = "+1" + phonetemp;
      this.number = phonetemp;

      this.mutSetContact({
        number: this.number,
        fullname: this.name,
      });

    this.getMessages(this.page);
    },

    
    stopSms: function () {
      this.mutSetMessages([]);
      clearInterval(this.activeSms);
      this.mutShowSms(false);
      this.mutSetContact(null)
    },

    getSkip(page){
    return page*this.limit
    },
    getMessages(page) {
      
      const p = page ? page:0
      this.loading = true;
      const numer = this.contactSelect.number;
      if (numer != undefined && numer != null && numer != "") {
      getAPI(`/twiliocloud/getSMS/${this.limit}/${this.getSkip(p)}`, {
      params: { phoneNumber: numer },
      })
      .then((res) => {
      this.loading = false;
      const response = res.data.result;      
      this.smsS = response.reverse();     
      this.moreSms=res.data.count> this.limit
      setTimeout(() => {
        this.scrolltoBotton();
      }, 100);
      
     this.readSms();
      })
      .catch((error) => {
      this.loading = false;
      let mess = error.response.data.message;
      
      if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
      }
      notifyError(error.response.data, `An error occurred: ${mess}`);
      });
      } else {
      this.smsContact = [];
      }


    },

    async getMore() {
    this.page++;
    if (this.contactSelect != null) {
    this.loading = true;
    const numer = this.contactSelect.number;
    if (numer != undefined && numer != null && numer != "") {
    getAPI(`/twiliocloud/getSMS/${this.limit}/${this.getSkip(this.page)}`, {
    params: { phoneNumber: numer },
    })
    .then((res) => {
    this.loading = false;
    const responseT = res.data.result;
    const response = responseT.reverse();
    this.smsS= response.concat(this.smsS);
    
    this.moreSms=res.data.count> this.getSkip(this.page+1)
    this.readSms();
    })
    .catch((error) => {
    this.loading = false;
    let mess = error.response.data.message;
    
    if (mess.includes("[")) {
    mess = mess.replace("[", "");
    mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
    });
    } else {
    this.smsContact = [];
    }
    }
    },
    sendOrReceived(numberFrom) {
      return numberFrom === this.$store.state.profile.phone
        ? "send"
        : "received";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${day}/${month}/${year}`;
    },
    isDiferentMessage(index, messages) {
      if (messages[index - 1]) {
        const day1 = moment(messages[index - 1].createAt).get("day");
        const day2 = moment(messages[index].createAt).get("day");

        if (day1 != day2) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    scrolltoBotton() {
      
      if (this.showSmsGlobal) {
        const doc = document.querySelector("#scrollable");
        doc.scrollIntoView({
          behavior: "smooth",
        });
      }
    },

    readSms() {
    if (this.contactSelect != undefined && this.contactSelect != null) {
    
    const unread = this.smsS.filter(
    (ms) => ms.from == this.contactSelect.number && ms.status == "UNREAD"
    );
   
    if (unread.length != 0) {
    this.actReadMessages(unread).then(() => {});
    }
    }
    },
  },
  destroyed() {
   this.stopSms();
  
  },
};
</script>
<style lang="scss" scoped>
.texttoolbar {
  width: 250px;
  text-align: center;
}
</style>
