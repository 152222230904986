import { RouteConfig } from "vue-router";

const CrmSearchRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Search",
    component: () => import("@/views/GlobalSearch/SearchList.vue"),
    meta: {
      requiresLogged: true,
    },
    props: true,
  },
];

export default CrmSearchRoutes;
