/* eslint-disable */
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifySuccess,
} from "@/components/Notification";
import store from "@/store";
import SendedSMS from "@/models/SendedSMS";
import { Contact, SMS } from "@/models/Sms";
import uniqBy from "lodash/uniqBy";
import formatPhone from "@/utils/formatPhone";
interface ImgUpload {
  file: [];
  number: string;
}

export async function getUnread(
  { commit }: any,
  phoneNumber: string
): Promise<any> {
  const responseData = (await getAPI(`/twiliocloud/getUnreadSmsCount`)).data;
  if (responseData.length != 0) {
    commit("mutContainUnread", true);
    commit("mutSetUnreads", responseData);
  } else {
    commit("mutSetUnreads", []);
    commit("mutContainUnread", false);
  }
}

export async function actContac({ commit }: any, uuid: string): Promise<any> {
  try {
    commit("mutLoading", true);
    const responseData = (await getAPI("/twiliocloud/getSmsNumbers/" + uuid))
      .data;

    const arrayFix: Contact[] = [];
    responseData.forEach((element: Contact) => {
      const ele = element;
      let num = ele.number;
      if (num.includes("+1")) {
        num = num.replace("+1", "");
      }
      num = num.replaceAll("\t", "");
      num = num.replaceAll(" ", "");
      num = formatPhone(num);
      num = "+1" + num;
      ele.number = num;
      arrayFix.push(ele);
    });

    const removedDoubles: Contact[] = uniqBy(arrayFix, "number");

    const contactsFix: any[] = [];
    removedDoubles.forEach((rd: Contact) => {
      const name = _getName(rd);
      const phone = rd.number;
      const customerUuid = rd.customer_uuid ? rd.customer_uuid : "";
      const origin = _getOrigin(rd);
      contactsFix.push({
        customer_uuid: customerUuid,
        number: phone,
        name: name,
        origin: origin,
        fromcontact: false,
        unread_sms: rd.unread_sms,
      });
    });
    const userContacts = store.state.userContacts;

    if (userContacts.length != 0) {
      for (let index = 0; index < contactsFix.length; index++) {
        const contats = contactsFix[index];
        for (let y = 0; y < userContacts.length; y++) {
          const uscont = userContacts[y];

          if (contats.number == (uscont as any).number) {
            contats.name = (uscont as any).alias;
            contats.origin = (uscont as any).origin;
            contats.fromcontact = true;

            contactsFix[index] = contats;
          }
        }
      }
    }

    commit("mutcontacts", contactsFix);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actContactUnrelated(
  { commit }: any,
  uuid: string
): Promise<any> {
  try {
    commit("mutLoading", true);
    const responseData = (await getAPI("/twiliocloud/getUnrelatedSmsNumbers"))
      .data;

    const arrayFix: any = [];
    responseData.forEach((element: any) => {
      const ele = element;
      let num = ele.s_from;
      if (num.includes("+1")) {
        num = num.replace("+1", "");
      }
      num = num.replaceAll("\t", "");
      num = num.replaceAll(" ", "");
      num = formatPhone(num);
      num = "+1" + num;
      ele.s_from = num;
      arrayFix.push(ele);
    });

    const removedDoubles = uniqBy(arrayFix, "s_from");

    const contactsFix: any[] = [];
    removedDoubles.forEach((rd: any) => {
      const phone = rd.s_from;
      contactsFix.push({
        number: phone,
        fromUnrelated: true,
        unread_sms: rd.unread_sms,
      });
    });
    commit("mutcontacts", contactsFix);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

function _getName(contact: Contact) {
  if (contact.customer_fullname != null) {
    return contact.customer_fullname;
  }
  if (contact.lead_fullname != null) {
    return contact.lead_fullname;
  }
  return contact.number;
}
function _getOrigin(contact: Contact) {
  if (contact.customer_fullname != null) {
    return "Customer";
  }
  if (contact.lead_fullname != null) {
    return "Lead";
  }

  if (contact.employee_fullname != null) {
    return "Employee";
  }
  return "";
}

export async function actListMessages({ commit, dispatch }: any): Promise<any> {
  try {
    commit("mutLoading", true);
    let responseData = (await getAPI("/twiliocloud/getSMS/500/0")).data;
    if (responseData.result.length < responseData.count) {
      responseData = (
        await getAPI(`/twiliocloud/getSMS/${responseData.count}/0`)
      ).data;
    }

    let dataresp = responseData.result;

    dataresp.map((sms: SMS) => {
      if (!sms.from.includes("+1")) {
        sms.from = "+1" + sms.from;
        sms.from = sms.from.replaceAll(" ", "");
      }
      if (!sms.to.includes("+1")) {
        sms.to = "+1" + sms.to;
        sms.to = sms.to.replaceAll(" ", "");
      }

      sms.from = sms.from.replaceAll("(", "");
      sms.to = sms.to.replaceAll("(", "");
      sms.from = sms.from.replaceAll(")", "");
      sms.to = sms.to.replaceAll(")", "");
      sms.from = sms.from.replaceAll("-", "");
      sms.to = sms.to.replaceAll("-", "");
      sms.from = sms.from.replaceAll(" ", "");
      sms.to = sms.to.replaceAll(" ", "");
      sms.from = sms.from.replaceAll("\t", "");
      sms.to = sms.to.replaceAll("\t", "");
    });

    if (
      store.state.profile.rol == "ADMIN" ||
      store.state.profile.rol == "SUPER"
    ) {
      dataresp = dataresp.filter(
        (msg: SMS) =>
          msg.to == store.state.profile.phone ||
          msg.from == store.state.profile.phone
      );
    }

    const unread = dataresp.filter(
      (message: SMS) =>
        message.status == "UNREAD" && message.to == store.state.profile.phone
    );

    commit("mutSetMessages", dataresp);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actUploadImage(
  { commit, dispatch }: any,
  imgupload: ImgUpload
): Promise<any> {
  commit("mutLoadingImg", true);

  imgupload.file.forEach((element: File) => {
    const formDataPhotoId = new FormData();

    const imgblob = new Blob([element], { type: element.type });
    formDataPhotoId.append("media", imgblob);
    getAPI
      .post("/twiliocloud/uploadMediaFile", formDataPhotoId)
      .then((res) => {
        const mediaUrl: Array<string> = [];
        mediaUrl.push(res.data.mediaUrl);

        dispatch("actSendMessage", {
          number: imgupload.number,
          mediasUrl: mediaUrl,
          message: "",
        });
      })
      .catch((error) => {
        commit("mutLoadingImg", false);
        let mess = error.response.data.message.toString();

        notifyError(error.response.data, `An error occurred: ${mess}`);
      });
  });
}

export async function actSendMessage(
  { commit }: any,
  { number, message, mediasUrl }: SendedSMS
): Promise<any> {
  try {
    commit("mutLoading", true);
    if (mediasUrl) {
      await getAPI.post("/twiliocloud/sendsms", { message, number, mediasUrl });
    } else {
      await getAPI.post("/twiliocloud/sendsms", { message, number });
    }

    commit("mutLoading", false);
    commit("mutLoadingImg", false);
  } catch (error: any) {
    commit("mutLoading", false);
    commit("mutLoadingImg", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actReadMessages(
  { commit, dispatch }: any,
  messages: Array<SMS>
): Promise<any> {
  try {
    commit("mutLoading", true);
    const smss = messages.map((ms: SMS) => {
      return ms.uuid;
    });

    await getAPI.put("/twiliocloud/readSms", { smsUuids: smss });
    commit("mutLoading", false);
    dispatch("getUnread");
  } catch (error: any) {
    commit("mutLoading", false);

    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actMessagesPerNumber(
  { commit }: any,
  number: string
): Promise<any> {
  try {
    commit("mutLoading", true);
    let num = number;
    if (num.includes("+1")) {
      num = num.replace("+1", "");
    }
    num = num.replaceAll("\t", "");
    num = num.replaceAll(" ", "");
    num = formatPhone(num);
    num = "+1" + num;

    const responseData = (
      await getAPI.post("/twiliocloud/filterSms", {
        whateverNumber: num,
        limit: 500,
        offset: 0,
      })
    ).data.result;
    commit("mutSetMessagesPatient", responseData);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);

    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
