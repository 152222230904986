<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="row">
    {{ items }}
    <v-autocomplete
    :items="items"
    :disabled="disabled"
    :loading="loading"
    v-model="valueC"
    :search-input.sync="search"
    :label="name"
    item-text="fullname"
    item-value="uuid"
    autocomplete="off"
    clearable
    hide-details
    outlined
    dense
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
        <v-list-item-subtitle
          v-if="data.item.from"
          class="text-capitalize"
          v-html="data.item.from"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
  </div>
 
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { mapGetters } from "vuex";
export default {
  name: "filter-global",
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => "Global Search",
    },
  },
  data() {
    return {
      valueC: null,
      loading: false,
      items: [],
      search: null,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
  },
  watch: {
    valueC(val) {
      if (val != null && val != undefined && val != "" && val.length > 4) {
        this.$emit("selectC", val);
      }
      if (val == null) {
        this.$emit("selectC", val);
      }
    },
    search(val) {
      if (val && val.length > 4) {
        this.getClients();
      } else {
        this.items = [];
      }
    },
  },
  methods: {
    getClients() {
      this.loading = true;
      let body = {
        limit: 250,
        offset: 0,
        query: this.search,
      };
      getAPI.post("/customer/globalSearch", body).then((res) => {
     this.groupResult (res.data);
        this.loading = false;
      });
    },
    groupResult(data){
      const arry = [];
      if (data) {
          
      const policiesdata = data.policies.result;
      const policiesnumber = data.policies.count;
      const leadsdata = data.leads.result;
      const leadsnumber = data.leads.count;
      const customersdata = data.customers.result;
      const customernumber = data.customers.count;
      if (policiesnumber!=0) {
        arry.push({from:'Policy', fullname:'POLICIES',count:policiesnumber,uuid:null})
      }
      if (leadsnumber!=0) {
        arry.push({from:'lead', fullname:'LEADS',count:leadsnumber,uuid:null})
      }
      if (customernumber!=0) {
        arry.push({from:'Customer', fullname:'CUSTOMERS',count:customernumber,uuid:null})
      }
      }
      this.items= arry     
    }
  },
};
</script>
<style lang=""></style>
